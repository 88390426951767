import Header from './components/Header';
import Disco from './components/Disco';
import './index.css';

function App() {
  return (
    <div className="App">
      <Header />
      <Disco />
    </div>
  );
}

export default App;
