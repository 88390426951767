import React from 'react';
import ExcessiveStep from '../covers/excessive-step.jpeg';
import ParadoxOfChoice from '../covers/paradox-of-choice.jpg';
import Addenda from '../covers/addenda.jpg';
import Monocosm from '../covers/monocosm.jpg';
import Code280 from '../covers/code280.jpg';
import ANiceDreamInHell from '../covers/a-nice-dream-in-hell.jpg';
import ADominoFirst from '../covers/a-domino-first.jpg';
import Default0011 from '../covers/default-0011.jpg';
import Code050 from '../covers/code050.jpg';
import Batch0007 from '../covers/batch0007.jpg';
import Code666 from '../covers/code666.jpg';
import AA0003 from '../covers/aa0003.jpg';
import PocketProtection from '../covers/pocket-protection.jpg';
import Enders from '../covers/enders.jpg';
import Clipe from '../covers/clipe.png';
import UnorganisedMusic from '../covers/unorganised-music.jpg';
import SlantDeviations from '../covers/slant-deviations.jpg';
import BaggySheps from '../covers/baggy-sheps.jpg';
import Flowers from '../covers/flowers.png';
import BoneStructure from '../covers/bone-structure.png';
import Miscellaneous from '../covers/miscellaneous.png';

const Disco = () => {
  return (
    <div className="full">
      <div className="row">
        <div className="record">
          <a href="https://calumgunn.bandcamp.com/album/excessive-step">
            <img src={ExcessiveStep} alt="Calum Gunn - Excessive Step" />
          </a>
        </div>
        <div className="record">
          <a href="https://ooh-sounds.bandcamp.com/album/calum-gunn-paradox-of-choice">
            <img src={ParadoxOfChoice} alt="Calum Gunn - Paradox of Choice" />
          </a>
        </div>
        <div className="record">
          <a href="https://shop.cpurecords.net/album/addenda">
            <img src={Addenda} alt="Calum Gunn - Addenda" />
          </a>
        </div>
        <div className="record">
          <a href="https://calumgunn.bandcamp.com/album/monocosm">
            <img src={Monocosm} alt="Calum Gunn - Monocosm" />
          </a>
        </div>
      </div>

      <div className="row">
        <div className="record">
          <a href="https://co-dependent.bandcamp.com/album/code280">
            <img src={Code280} alt="Calum Gunn - CODE280" />
          </a>
        </div>
        <div className="record">
          <a href="https://newyorkhaunted.bandcamp.com/album/nyh129-ionic-column-a-nice-dream-in-hell">
            <img src={ANiceDreamInHell} alt="Ionic Column - A Nice Dream In Hell" />
          </a>
        </div>
        <div className="record">
          <a href="https://acrnym.bandcamp.com/album/miscellaneous">
            <img src={Miscellaneous} alt="Acrnym - Miscellaneous" />
          </a>
        </div>
        <div className="record">
          <a href="https://disformation.bandcamp.com/album/a-domino-first">
            <img src={ADominoFirst} alt="Calum Gunn - A Domino First [Gabber Studies]" />
          </a>
        </div>
      </div>

      <div className="row">
        <div className="record">
          <a href="https://sm-ll.bandcamp.com/album/default-0011">
            <img src={Default0011} alt="Calum Gunn - DEFAULT 0011" />
          </a>
        </div>
        <div className="record">
          <a href="https://co-dependent.bandcamp.com/album/code050">
            <img src={Code050} alt="Calum Gunn - CODE050" />
          </a>
        </div>
        <div className="record">
          <a href="https://sm-ll.bandcamp.com/album/batch-0007">
            <img src={Batch0007} alt="Calum Gunn - BATCH0007" />
          </a>
        </div>
        <div className="record">
          <a href="https://co-dependent.bandcamp.com/album/code666">
            <img src={Code666} alt="Calum Gunn - CODE666" />
          </a>
        </div>
      </div>

      <div className="row">
        <div className="record">
          <a href="https://flufrecs.bandcamp.com/album/aa0003">
            <img src={AA0003} alt="Calum Gunn - AA0003" />
          </a>
        </div>
        <div className="record">
          <a href="https://conditionalrecs.bandcamp.com/album/pocket-protection">
            <img src={PocketProtection} alt="Calum Gunn - Pocket Protection" />
          </a>
        </div>
        <div className="record">
          <a href="https://newyorkhaunted.bandcamp.com/album/nyhdo04-calum-gunn-enders">
            <img src={Enders} alt="Calum Gunn - Enders" />
          </a>
        </div>
        <div className="record">
          <a href="https://babavanga.bandcamp.com/album/calum-gunn-clipe/">
            <img src={Clipe} alt="Calum Gunn - Clipe" />
          </a>
        </div>

      </div>

      <div className="row">
        <div className="record">
          <a href="https://boomkat.com/products/unorganised-music">
            <img src={UnorganisedMusic} alt="Calum Gunn - Unorganised Music" />
          </a>
        </div>
        <div className="record">
          <a href="https://conditionalrecs.bandcamp.com/album/slant-deviations">
            <img src={SlantDeviations} alt="Calum Gunn & Sebastian Camens - Slant Deviations" />
          </a>
        </div>
        <div className="record">
          <a href="https://calumgunn.bandcamp.com/album/baggy-sheps">
            <img src={BaggySheps} alt="Calum Gunn - Baggy Sheps" />
          </a>
        </div>
        <div className="record">
          <a href="https://acrnym.bandcamp.com/album/flowers">
            <img src={Flowers} alt="Acrnym - Flowers" />
          </a>
        </div>
      </div>

      <div className="row">
        <div className="record">
          <a href="https://acrnym.bandcamp.com/album/bone-structure">
            <img src={BoneStructure} alt="Acrnym - Bone Structure" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Disco;
